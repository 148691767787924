.modal {
    z-index: 99;
    position: absolute;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    width: 600px;
    height: 600px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top:57px;
}

.modalheader {
    display: block;
    background-color: cornflowerblue;
    height: 25px;
    width:100%;
    margin-bottom: 15px;
    cursor: default;
}

.terminal {
    background-color: #fff7ef;
    color: #026dac;
    font-family: "Courier New", Courier, monospace;
    border: 2px solid #3800ff;
    padding: 10px;
    margin: 20px;
    overflow: auto;
    height: 250px;
    width: 400px;
    cursor: default;
}

.user {
    user-select: none;
    pointer-events: none;
}

.badinput {
    background-color:rgb(240, 178, 199);
}

.happyinput {
    background-color: aquamarine;
}

.tinytext {
    font-size: 13px;
    display: block;
    cursor: default;
}

.kdeg {
    display: inline-block;
    padding: 1px 9px;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #3daee9;
    border: 1px solid #3daee9;
    border-radius: 4px;
    margin-left: 1px;
    background: linear-gradient(to bottom, #3daee9 0%, #2e8ec8 100%);
    box-shadow: inset 0 1px 0 #6ad2f4, inset 0 -1px 0 #1f6ea7,
      0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .kde {
    display: inline-block;
    padding: 1px 9px;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #3daee9;
    border: 1px solid #3daee9;
    border-radius: 4px;
    margin-left: 1px;
  }
  
  .kdehover {
    display: inline-block;
    padding: 1px 9px;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    border: 1px solid #3daee9;
    border-radius: 4px;
    margin-left: 1px;
    background-color: #2e8ec8;
    border-color: #2e8ec8;
  }
  
  .kde:active {
    background-color: #281fa7;
    border-color: #1f21a7;
    background-color: #531fa7;
  }

  .disabled {
    display: none;
  }

  .exit {
    position: fixed;
    right: 3px;
    top: 2px;

    display: inline-block;
    padding: 1px 9px;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #e93da7;
    border: 1px solid #e93da7;
    border-radius: 4px;
    margin-left: 1px;
    background: linear-gradient(to bottom, #e93da7 0%, #b83084 100%);
    box-shadow: inset 0 1px 0 #e981c1, inset 0 -1px 0 #851b5c,
      0 1px 2px rgba(0, 0, 0, 0.1);
  }