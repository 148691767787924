.installer p {
  font-family: Arial, sans-serif;
  font-size: 3rem;
  color: red;
  text-align: center;
  margin-top: 100px;
  animation: heartbeat 1s infinite;
  cursor: cell;
}

.smallemoji {
  font-size: 1.75rem;
}

.install {
  position: relative;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 40%;
}

.kdeg {
  display: inline-block;
  padding: 1px 9px;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #3daee9;
  border: 1px solid #3daee9;
  border-radius: 4px;
  margin-left: 1px;
  background: linear-gradient(to bottom, #3daee9 0%, #2e8ec8 100%);
  box-shadow: inset 0 1px 0 #6ad2f4, inset 0 -1px 0 #1f6ea7,
    0 1px 2px rgba(0, 0, 0, 0.1);
}

.kde {
  display: inline-block;
  padding: 1px 9px;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: #3daee9;
  border: 1px solid #3daee9;
  border-radius: 4px;
  margin-left: 1px;
}

.kdehover {
  display: inline-block;
  padding: 1px 9px;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  border: 1px solid #3daee9;
  border-radius: 4px;
  margin-left: 1px;
  background-color: #2e8ec8;
  border-color: #2e8ec8;
}

.kde:active {
  background-color: #281fa7;
  border-color: #1f21a7;
  background-color: #531fa7;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.3);
  }
  75% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
